// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function checkDate(field) {
  if (field.value.includes("/") === false) {
    return false;
  }

  var [day, month, year] = field.value.split("/");
  switch (parseInt(month)) {
    case 1:
      return day.match(/^(([0-2][0-9])|(3[0-1]))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
    case 2:
      if (parseInt(year) % 4 === 0) {
        return day.match(/^(([0-2][0-9]))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
      } else {
        return day.match(/^(([0-2][0-8]))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
      }
    case 3:
      return day.match(/^(([0-2][0-9])|(3[0-1]))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
    case 4:
      return day.match(/^(([0-2][0-9])|(30))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
    case 5:
      return day.match(/^(([0-2][0-9])|(3[0-1]))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
    case 6:
      return day.match(/^(([0-2][0-9])|(30))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
    case 7:
      return day.match(/^(([0-2][0-9])|(3[0-1]))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
    case 8:
      return day.match(/^(([0-2][0-9])|(3[0-1]))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
    case 9:
      return day.match(/^(([0-2][0-9])|(30))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
    case 10:
      return day.match(/^(([0-2][0-9])|(3[0-1]))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
    case 11:
      return day.match(/^(([0-2][0-9])|(30))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);
    case 12:
      return day.match(/^(([0-2][0-9])|(3[0-1]))$/) && month.match(/^((0[1-9])|1[0-2])$/) && year.match(/^\d\d\d\d$/);

    default:
      return false;
  }
}

export function checkDateField(field) {
  var errorMsg = document.getElementById("error-message");
  var button = document.getElementById("form-submit-button");
  if (checkDate(field)) {
    errorMsg.classList.add("hidden");
    button.removeAttribute("disabled");
  } else {
    errorMsg.classList.remove("hidden");
    button.setAttribute("disabled", true);
  }
}

export function checkFileSize(filetag) {
  var button = document.getElementById("form-submit-button");
  var files = filetag.files;
  var correct = 0;
  for (const file in files) {
    var filesize = (files[file].size / 1024 / 1024).toFixed(4);
    if (files[file].name != "item" && typeof files[file].name != "undefined" && filesize <= 2) {
      correct += 1;
    }
  }

  if (correct !== 1) {
    alert("Upload een file van maximaal 2 MB");
    filetag.value = "";
  }
}
